import './list.scss';
import dataApi from '@/api/system/type.js';

export default {
  name: 'dict-type-list',
  components: {},
  data () {
    return {
      page: {
        pageSize: 10,
        pageSizes: [3, 10, 20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        // ...this.$config.table,
        ...this.$store.getters.tableConfig,
        column: [
          {
            // 字典ID dict_id
            label: '字典ID',
            addDisplay: false,
            editDisplay: false,
            hide: true,
            prop: 'dict_id'
          },
          {
            // 字典名称   dict_name
            label: '字典名称',
            searchRange: true,
            search: true,
            prop: 'dict_name'
          },
          {
            // 字典类型  dict_type
            label: ' 字典类型 ',
            prop: 'dict_type',
            slot: true
          },
          {
            label: '状态',
            row: true,
            span: 12,
            type: 'radio',
            value: 0,
            dicData: [
              {
                label: '正常',
                value: 0
              },
              {
                label: '停用',
                value: 1
              }
            ],
            prop: 'status'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          },
          {
            // 备注 remark
            label: '备注',
            prop: 'remark',
            span: 24,
            type: 'textarea'
          }
        ]
      },
      searchData: {}
    };
  },
  computed: {},
  mounted () {
  },
  filters: {},
  methods: {
    // 点击clickLink
    clickLink (e) {
      console.log('点击clickLink', `/dict/data/${e.dict_type}`);
      this.$router.push(`/dictionary/data/${e.dict_type}`);
    },
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange (params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit (form, done, loading) {
      console.log('form', form, loading);
      dataApi.add.r({ ...form, dict_id: 0 }).then(() => {
        this.getList();
        done(form);
        loading();
      });
      done();
      // done(form);
    },
    // 删除
    rowDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ dict_id: form.dict_id }).then(res => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate (form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then(() => {
        this.getList();
        done(form);
        loading();
      });
      done();
    },
    // 获取
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
